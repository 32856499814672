/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~swiper/swiper-bundle';

//@import '~@linnkr/ecommerce-shared/assets/styles/typo';
@import 'rtl';

/* Helper classes */
.pointer, .clickable {
  cursor: pointer;
}

.clickable--blue {
  :hover {
    color: var(--blue-main);
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-h-center {
  justify-content: center;
}

.flex-v-center {
  align-items: center;
}

.hidden-on-popup {
  display: none !important;
}

// Phone weak ltr numbers fix
input[type='tel'] {
  unicode-bidi: bidi-override;
  direction: LTR;
}

// Global fix for timepicker component
.timepicker {
  direction: ltr;
}

// Shared component styles - Use wrapper class as component host class
.order-styles {
  .order-status {
    padding: 0 6px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--active {
      background-color: var(--blue-text);
    }

    &--inProcess {
      background-color: var(--no-relevant-text);
    }

    &--cancelled {
      background-color: var(--red);
    }

    &--delivered {
      background-color: var(--no-relevant-text);
    }

    &--add-on {
      background-color: black;
    }
  }
}
