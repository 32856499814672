$corners-min-width: 30px;
$mat-label-margin: 25px;

// Consider https://css-tricks.com/almanac/selectors/d/dir/

.page-no-borders {
  mat-form-field:not(.custom-borders)
    .mat-form-field-appearance-outline
    .mat-form-field-outline {
    color: var(--primary_color) !important;
    border-width: 3px;
  }

  /*
    This css causes the position of the outline gap
    The idea is force the start and end of the mat form to have a min width
    So it looks rounded, otherwise for small inputs it looks squared
    */
  .mat-form-field-outline-start {
    min-width: $corners-min-width !important;
  }

  .mat-form-field-outline-end {
    min-width: $corners-min-width !important;
  }

  /*
    Making the corners of the mat-form-field-outline rounded.
    The component is splited in 3 parts, start, gap and end
    So we need to add the right border radius depending of the rtl
    */
  [dir='ltr'] mat-form-field:not(.custom-borders).mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 7px 0 0 7px !important;
    border-right-style: none;
  }

  [dir='ltr'] mat-form-field:not(.custom-borders).mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 7px 7px 0 !important;
    border-left-style: none;
  }

  [dir='rtl'] mat-form-field:not(.custom-borders).mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 0 7px 7px 0 !important;
    border-left-style: none;
  }

  [dir='rtl'] mat-form-field:not(.custom-borders).mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 7px 0 0 7px !important;
    border-right-style: none;
  }

  /*
    This makes the label of the input to be at the beginning
    when there is no text in the input, otherwise it will appear
    with the same marging that the gap has
    */
  [dir='ltr'] .mat-form-field-hide-placeholder .mat-form-field-label {
    margin-left: 0 !important;
  }

  /*
    This makes the label appear inside the gap when the user is typing
    */
  [dir='ltr'] .mat-focused .mat-form-field-label {
    color: black !important;
    margin-left: $mat-label-margin;
  }

  /*
    This makes the label appear inside the gap when the user finish typing
    and the input lose focus
    */
  [dir='ltr'] .ng-valid .mat-form-field-label {
    color: black !important;
    margin-left: $mat-label-margin;
  }

  /*
    This makes the label appear inside the gap when the input is disabled
    */
  [dir='ltr'] .mat-form-field-disabled .mat-form-field-label {
    margin-left: $mat-label-margin;
  }

  /*
    Same as before but for rtl
    */
  [dir='rtl'] .mat-form-field-disabled .mat-form-field-label {
    margin-right: $mat-label-margin;
  }

  /*
    This makes the label appear inside the gap when the input value is invalid
    */
  [dir='ltr'] .ng-invalid .mat-form-field-label {
    color: black !important;
    margin-left: $mat-label-margin;
  }

  /*
    Same as before but for rtl
    */
  [dir='rtl'] .mat-form-field-hide-placeholder .mat-form-field-label {
    margin-right: 0 !important;
  }

  [dir='rtl'] .ng-invalid .mat-form-field-label {
    color: black !important;
    margin-right: $mat-label-margin;
  }

  [dir='rtl'] .ng-valid .mat-form-field-label {
    color: black !important;
    margin-right: $mat-label-margin;
  }

  [dir='rtl'] .ng-invalid .mat-form-field-label {
    color: black !important;
    margin-right: $mat-label-margin;
  }

  .mat-checkbox-layout {
    white-space: normal !important;
  }
  width: 100%;
  .main-button {
    background: var(--primary_color);
    margin-bottom: auto;
    width: 100%;
    color: var(--pure-white);
    border-radius: 24.5px;
    font-weight: 500;
    outline: none;
    height: 40px;
  }
  .secundary-button {
    background: var(--pure-white);
    text-transform: capitalize;
    width: 100%;
    color: var(--primary_color);
    border-radius: 24.5px;
    font-weight: 500;
    outline: none;
    border: solid var(--primary_color) 2px;
  }
  .mt-2 {
    margin-top: 2px;
  }
}

.mat-radio-checked {
  .mat-radio-inner-circle {
    background-color: #017ee5 !important;
    transform: scale(0.7) !important;
  }
}

.mat-snack-bar-container {
  &.danger span {
    color: var(--red);
  }
}

.non-padded .mat-expansion-panel-body {
  padding: 0 !important;
}
