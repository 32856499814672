.modal-round-corners {
    --height: 90%;
    --max-height: 90%;
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--porcelain);
    align-items: flex-end;
    --backdrop-opacity: 0.3;
    --border-radius: 24px 24px 0px 0px;
  }

.in-case-forgot-modal {
  --height: 90%;
  --min-height: 511px;
  --max-width: 524px;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--porcelain);
  --backdrop-opacity: 0.3;
  --border-radius: 9px;
}


  .centered-small-modal {
    --height: 60%;
    --max-height: 60%;
    --backdrop-opacity: 0.3;
    --border-radius: 8px;
    --width: 90%;
 }
  