.currency-amount > *{
    text-align: center;
    color: var(--primary_color) !important;
    display: inline-block;
}

.currency-amount::first-letter {
    padding-right: 0.3em;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.hide-overflow-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &-1 {
        @extend .hide-overflow-text;
        -webkit-line-clamp: 1;
    }

    &-2 {
        @extend .hide-overflow-text;
        -webkit-line-clamp: 2;
    }
}

.muted {
  color:#85878c;
}

.mini-logo {
  margin: 0 10px;
}

.header-popover {
  .popover-content {
    width: 100% !important;
  }
}
@media screen and (min-width: 960px) {
  .additional-panels mat-expansion-panel {
    max-width: 50%;
  }
}

.cdk-overlay-pane {
  .brands-wrapper {
    padding: 20px 102px !important;
    justify-content: center !important;
    overflow: auto;
    .brand {
      margin: 5px 5px!important;
      width: 100px !important;
      height: 100px !important;
    }
  }

  .category-wrapper {
    gap: 10px 10px!important;
    overflow: auto;
  }
}
