.rating.direction-rtl {
    direction: rtl;
}

.rating.direction-ltr {
    direction: ltr;
}

.rating.color-default .star-container .star svg {
    fill: #999 !important;
}

.rating.color-default .star-container .star i {
    color: #999 !important;
}

.rating.color-ok .star-container .star svg {
    fill: #0E1D2B !important;
}

.rating.color-ok .star-container .star i {
    color: #0E1D2B !important;
}

.rating.color-positive .star-container .star svg {
    fill: #0E1D2B !important;
}

.rating.color-positive .star-container .star i {
    color: #0E1D2B !important;
}

.rating.color-negative .star-container .star svg {
    fill: #0E1D2B !important;
}

.rating.color-negative .star-container .star i {
    color: #0E1D2B !important;
}