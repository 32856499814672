
.mt-0 {
    margin-top: 0;
}
  
.mt-1 {
    margin-top: calc(#{var(--spacer)} * 0.25);
}

.mt-2 {
    margin-top: calc(#{var(--spacer)} * 0.5);
}

.mt-3 {
    margin-top: var(--spacer);
}

.mt-4 {
    margin-top: calc(#{var(--spacer)} * 1.5);
}

.mt-5 {
    margin-top: calc(#{var(--spacer)} * 3);
}

.mb-0 {
    margin-bottom: 0;
}
  
.mb-1 {
    margin-bottom: calc(#{var(--spacer)} * 0.25);
}

.mb-2 {
    margin-bottom: calc(#{var(--spacer)} * 0.5);
}

.mb-3 {
    margin-bottom: var(--spacer);
}

.mb-4 {
    margin-bottom: calc(#{var(--spacer)} * 1.5);
}

.mb-5 {
    margin-bottom: calc(#{var(--spacer)} * 3);
}

.ml-0 {
    margin-left: 0;
}
  
.ml-1 {
    margin-left: calc(#{var(--spacer)} * 0.25);
}

.ml-2 {
    margin-left: calc(#{var(--spacer)} * 0.5);
}

.ml-3 {
    margin-left: var(--spacer);
}

.ml-4 {
    margin-left: calc(#{var(--spacer)} * 1.5);
}

.ml-5 {
    margin-left: calc(#{var(--spacer)} * 3);
}

.mr-0 {
    margin-right: 0;
}
  
.mr-1 {
    margin-right: calc(#{var(--spacer)} * 0.25);
}

.mr-2 {
    margin-right: calc(#{var(--spacer)} * 0.5);
}

.mr-3 {
    margin-right: var(--spacer);
}

.mr-4 {
    margin-right: calc(#{var(--spacer)} * 1.5);
}

.mr-5 {
    margin-right: calc(#{var(--spacer)} * 3);
}

.m-0 {
    margin: 0;
}
  
.m-1 {
    margin: calc(#{var(--spacer)} * 0.25);
}

.m-2 {
    margin: calc(#{var(--spacer)} * 0.5);
}

.m-3 {
    margin: var(--spacer);
}

.m-4 {
    margin: calc(#{var(--spacer)} * 1.5);
}

.m-5 {
    margin: calc(#{var(--spacer)} * 3);
}