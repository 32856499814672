:root {
    --spacer: 8px;
    // Border
     --border_radius_1: 5px;
    --border_radius_default: 16px;
    --border-radius-big: 24.5px;

    // Colors
    --primary_color: #0E1D2B;
    --cream_can: #F2BC55;
    --green_pea: #186348;
    --clinker: #332409;
    --firefly: #0C1A20;
    --tonys-pink: #E68E88;
    --blue-dianne: #1B3643;
    --facebook-color: #3b5998;
    --links: #5185e5;
    --pure-white: #fff;
    --divider-color:  rgb(224, 224, 224);
    --divider-color-dark:  rgb(189, 189, 189);
    --porcelain: #E7E8EA;
    --azure-radiance: #0089F5;
    --big-stone: #162D3D;
    --blue-charcoal: #000E1C;
    --placeholder-box: #ECF5FA;
    --placeholder-box-grey: #F0F2F3;
    --rolling-stone: #797E81;
    --oslo-gray: #85878C;
    --blue-light: #E7F4FE;
    --blue-text: #28A7FF;
    --no-relevant-text: #9BA1A7;
    --athens-gray: #F3F3F5;
    --banner-color: #ee8783;
    --text-danger: #dc3545;
    --soft-pink: #efe4e0;
    --fountain-blue: #58C2C7;
    --red: #E44343;
    --blue-main: #0600FF;
    --blue-2: #0f89ed;
    --gray-3: #cbd0d5;
    --turquoise: #5bc0d3;
}
