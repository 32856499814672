// this file will be included for rtl style overrides
:root {
  // direction: rtl;
}
html.rtl {
  // direction: rtl;
  .view-all mat-icon {
    transform: rotate(180deg);
  }

  .scroll-btn-start mat-icon {
    transform: rotate(180deg);
  }

  .scroll-btn-end mat-icon {
    transform: rotate(180deg);
  }
}
