@font-face {
  font-family: 'AlmoniTzarAAA';
  src: url('../../assets/fonts/aaa-fonts/almoni-tzar-regular-aaa.woff2') format('opentype');
}

// @font-face {
//   font-family: 'VarelaRound';
//   src: url('../../assets/fonts/Varela_Round/VarelaRound-Regular.ttf') format('truetype');
// }

@font-face {
  font-family: 'Heebo';
  src: url('../../assets/fonts/Heebo/Heebo-Regular.ttf');
}

@mixin web-font($family, $size, $weight: normal, $color: #0E1D2B) {
  font-family: $family, sans-serif !important;
  font-weight: $weight;
  font-size: $size;
  color: $color;
}

// *:not(.mat-icon):not(.material-icons) {
//   font-family: 'Heebo', sans-serif;
// }

.h1 {@include web-font('AlmoniTzarAAA', 30px, normal); letter-spacing: 1.04px}
.h1--white{@include web-font('AlmoniTzarAAA', 30px , normal, #fff ); letter-spacing: 1.04px}
.h1--bold {@include web-font('AlmoniTzarAAA', 30px, bold); letter-spacing: 1.04px}
.h2 {@include web-font('AlmoniTzarAAA', 24px, normal); letter-spacing: 1.04px}
.h2--bold {@include web-font('AlmoniTzarAAA', 24px, bold); letter-spacing: 1.04px}
.h2--white{@include web-font('AlmoniTzarAAA', 24px , normal, #fff ); letter-spacing: 1.04px}
.h3 {@include web-font('AlmoniTzarAAA', 20px, normal); letter-spacing: 1.04px}
.h3--white {@include web-font('AlmoniTzarAAA', 20px, normal, #fff); letter-spacing: 1.04px}
.h3--bold {@include web-font('AlmoniTzarAAA', 20px, bold); letter-spacing: 1.04px}
.h4 {@include web-font('AlmoniTzarAAA', 20px, normal); letter-spacing: 1.04px}
.h4--bold {@include web-font('AlmoniTzarAAA', 20px, bold); letter-spacing: 1.04px}
.h4--white {@include web-font('AlmoniTzarAAA', 20px, normal, #fff); letter-spacing: 1.04px}
.h4--accent {@include web-font('AlmoniTzarAAA', 20px, normal, #ec3690); letter-spacing: 1.04px}
.h4--accent--lightest {@include web-font('AlmoniTzarAAA', 20px, normal, #ff49a3); letter-spacing: 1.04px}
.h4--no-color {@include web-font('AlmoniTzarAAA', 20px, normal, 'transparent'); letter-spacing: 1.04px}
.h4--bold--white {@include web-font('AlmoniTzarAAA', 20px, bold, '#fff'); letter-spacing: 1.04px}
.h5 {@include web-font('AlmoniTzarAAA', 16px, normal); letter-spacing: 1.04px}
.h5--no-color {@include web-font('AlmoniTzarAAA', 16px, normal, 'transparent');letter-spacing: 1.04px}
.h5--white {@include web-font('AlmoniTzarAAA', 16px, normal, #fff);letter-spacing: 1.04px}
.h5--bold {@include web-font('AlmoniTzarAAA', 16px, bold); letter-spacing: 1.04px}
.h6 {@include web-font('AlmoniTzarAAA', 14px, normal); letter-spacing: 1.04px}
.h7 {@include web-font('AlmoniTzarAAA', 12px, normal);}
.h7--bold {@include web-font('AlmoniTzarAAA', 12px, 900);} // do not add letter spacing to h7

.p-banner {@include web-font('Heebo', 18px, medium);}
.p0 {@include web-font('Heebo', 18px, 500 );}
.p0--white {@include web-font('Heebo', 18px, 500, #fff);}
.p1 {@include web-font('Heebo', 16px, 500);}
.p1--bold {@include web-font('Heebo', 16px, bold);}
.p1--white {@include web-font('Heebo', 16px, 500, #fff);}
.p1--black {@include web-font('Heebo', 16px, 500, #000);}
.p1--bold--white {@include web-font('Heebo', 16px, bold, #fff);}
.p2 {@include web-font('Heebo', 14px, 500);}
.p2--bold {@include web-font('Heebo', 14px, bold);}
.p2--bold--white {@include web-font('Heebo', 14px, bold, #fff);}
.p3 {@include web-font('Heebo', 12px, 500);}
.p3--bold {@include web-font('Heebo', 12px, bold);}
.p3--bold--white {@include web-font('Heebo', 12px, bold, #fff);}

.p1-muted {@include web-font('Heebo', 16px, normal, #9ba1a7)}
.p2-muted {@include web-font('Heebo', 14px, normal, #9ba1a7)}

.p1-link {@include web-font('Heebo', 16px, 500, #0600ff)}
.p2-link {@include web-font('Heebo', 14px, 500, #0600ff)}
.text-italic {font-style: italic;}
.text-linethrough {text-decoration: line-through;}

.no-bg {background: none !important;}
// .p1 {@include web-font('VarelaRound', 16px, 500);}
// .p1--bold {@include web-font('VarelaRound', 16px, bold);}
// .p1--white {@include web-font('VarelaRound', 16px, 500, '#fff');}
// .p2 {@include web-font('VarelaRound', 14px, 500);}
// .p2--bold {@include web-font('VarelaRound', 14px, bold);}
// .p2--bold--white {@include web-font('VarelaRound', 14px, bold, '#fff');}
// .p3 {@include web-font('VarelaRound', 12px, 500);}
// .p3--bold {@include web-font('VarelaRound', 12px, bold);}
// .p3--bold--white {@include web-font('VarelaRound', 12px, bold, '#fff');}
