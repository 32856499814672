
   .main-button {
    //background: var(--primary_color);
     &.finalize-button:not(:disabled) {
       background: #2196f3 !important;
     }
    text-transform: capitalize;
    width: 100%;
    color: var(--pure-white);
    padding: 14px 14px;
    border-radius: 24.5px;
    outline: none;
    height: 40px;
  }

  .secondary-button {
    background: var(--pure-white);
    text-transform: capitalize;
    width: 100%;
    color: var(--primary_color);
    border-radius: 20px;
    padding: 14px 14px;
    font-weight: 500;
    outline: none;
    border: solid var(--primary_color) 2px;
}

  //.step-button-container {
  //  &.cart-saving {
  //    opacity: .3;
  //  }
  //}

.blue-button {
  background: var(--azure-radiance);
  text-transform: capitalize;
  width: 100%;
  color: var(--pure-white);
  padding: 14px 14px;
  border-radius: 24.5px;
  font-weight: 500;
  outline: none;
  height: 40px;
  cursor: pointer;

    &:disabled {
      background-color: dimgray;
    }
}

.outlined-button {
  background: transparent;
  text-transform: capitalize;
  width: 100%;
  color: var(--primary_color);
  border-radius: 20px;
  padding: 10px 14px;
  font-weight: 500;
  outline: none;
  border: solid var(--primary_color) 2px;
}
